.lead-details-container {
  .nav-underline-scrollspy {
    z-index: 1014;
    top: calc(var(--#{$prefix}navbar-top-height) + 1px);
  }
}

.deal-details {
  .nav-link {
    color: var(--#{$prefix}gray-1100);
    font-weight: $font-weight-semi-bold;
    .tab-icon-color {
      color: var(--#{$prefix}gray-700);
    }
    &.active {
      color: var(--#{$prefix}primary);
      .tab-icon-color {
        color: var(--#{$prefix}primary);
      }
    }
  }
}

.sticky-leads-sidebar {
  position: sticky;
  z-index: 1015;
  top: calc(var(--#{$prefix}navbar-top-height) + 21px);
}
.leads-table {
  th {
    position: relative;
    &::after {
      position: absolute;
      top: 35%;
    }
  }
}

.lead-details-offcanvas {
  max-width: 350px;
  top: var(--#{$prefix}navbar-top-height) !important;
  height: calc(100vh - var(--#{$prefix}navbar-top-height));
  padding: map-get($spacers, 4);
  padding-top: 1rem;
  @include media-breakpoint-up(md) {
    position: static !important;
    top: 1rem !important;
    padding: 0 !important;
    transform: none !important;
    max-width: unset;
    height: auto;
  }
}
.icon-wrapper-sm {
  position: relative;
  display: inline-block;
  &::before {
    position: absolute;

    content: '';
    height: 1.299rem;
    width: 1.299rem;
    border-radius: 0.243rem;
    top: 0.5rem;
    left: 0.2rem;
  }
}

.shadow-primary-100 {
  &:before {
    background-color: rgba(var(--#{$prefix}primary-rgb), 0.3);
  }
}
.shadow-info-100 {
  &:before {
    background-color: rgba(var(--#{$prefix}info-rgb), 0.3);
  }
}

.list-group-item {
  &.list-group-crm {
    padding: 0.3rem 0;
  }
}

.echart-contacts-width {
  min-width: 19rem !important;
  min-height: 320px;
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    min-width: unset !important;
  }
  @include media-breakpoint-up(md) {
    min-height: 435px !important;
  }
  @include media-breakpoint-up(lg) {
    min-height: 320px !important;
  }
  @include media-breakpoint-between(xl, xxl) {
    min-height: 430px !important;
  }
}

.table-stats {
  tr {
    th {
      @include media-breakpoint-up(sm) {
        &:nth-child(1) {
          width: 45%;
        }
        &:nth-child(1) {
          width: 5%;
        }
        &:nth-child(1) {
          width: 50%;
        }
      }
    }
    td {
      display: table-row;
      @include media-breakpoint-up(sm) {
        display: table-cell;
      }
    }
  }
}
